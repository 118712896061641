/* eslint-disable no-restricted-globals */

'use strict';

var clientSideValidation = require('../components/common/clientSideValidation');
var util = require('../util');
var layout = require('../layout');

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
 * sets the 'registered' cookie
 */
function setRegisteredCookie() {
    var d = new Date();
    d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toGMTString();

    document.cookie = `showRegisteredModal=true; ${expires}; secure='on'; path=/;`;
}

/**
 * deletes the 'registered' cookie
 * @param {string} cookieName - name of the cookie
 */
function deleteRegisteredCookie(cookieName = 'showRegisteredModal') {
    var d = new Date();
    d.setTime(d.getTime() + (0 * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toGMTString();

    document.cookie = `${cookieName}=false; ${expires}; secure='on'; path=/;`;
}

/**
 * Open create account modal
 * @param {Object} $this current element
 */
function getModalHtmlElement() {
    if ($('#newUserRegisterModal').length !== 0) {
        $('#newUserRegisterModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal g-modal g-modal-registerUser" id="newUserRegisterModal" role="dialog">'
        + '<div class="modal-dialog g-modal-dialog ">'
        + '<!-- Modal content-->'
        + '<div class="modal-content g-modal-content">'
        + '<div class="modal-body g-modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * On Submitting login on modal
 */
function submitCreateAccount() {
    $('body').on('click', '.js-createaccount-button', function (e) {
        e.preventDefault();
        $('.b-registration-error').addClass('hide');
        var $this = $(this).closest('form');
        var form = $(this).closest('form');
        var url = form.attr('action');
        var type = form.attr('method');
        var formdata = form.serialize();
        var registerForm = $('input[name="initializeRegisterForm"]');
        var inputFeedback = $(this).parents().find('#form-password-rules');
        inputFeedback.hide();
        var button = $(this);
        var buttonContainer = $(this).parent('div');
        buttonContainer.spinner().start();
        button.css('color', 'transparent');
        button.attr('disabled', 'true');

        if (clientSideValidation.checkPasswordContainsEmail(form) === false) {
            clientSideValidation.checkMandatoryField(form);
        }

        if (!form.find('input.is-invalid').length && (registerForm.length > 0 ? registerForm.val() === 'false' : true)) {
            registerForm.val(true);
            $.ajax({
                url: url,
                type: type,
                data: formdata,
                success: function (data) {
                    button.removeAttr('style');
                    buttonContainer.spinner().stop();
                    if (!data.success) {
                        button.prop('disabled', false);
                        if (data.invalidForm) {
                            var formValidation = require('base/components/formValidation');
                            formValidation($this, data);
                            form.trigger('login:error', data);
                        } else {
                            $('.b-registration-error').removeClass('hide');
                            var errorMsg = $('[data-analytics-track="error-span"]').text().trim() + ' ' + $('[data-analytics-track="error-link"]').text().trim();
                            $('body').trigger('register:error', { errorMsg: errorMsg });
                        }
                        registerForm.val(false);
                    } else {
                        form.trigger('login:success', data);
                        var customerEmail = form.find('[name="dwfrm_profile_customer_email"]').val();
                        $('body').trigger('register:success:analytics', {
                            customerNo: data && data.customerNo,
                            addToEmailList: data && data.addToEmailList,
                            email: customerEmail
                        });
                        if (data && data.addToEmailList) {
                            var isMobileView = layout.isMobileView();
                            $('body').trigger('global:emailSubscribeSuccess', {
                                email: customerEmail,
                                merkleCode: isMobileView ? 'registerEmailOptInForMobile' : 'registerEmailOptInForDesktop',
                                signupLocation: isMobileView ? 'registerEmailOptInForMobile' : 'registerEmailOptInForDesktop',
                                emailHashed: ''
                            });
                        }
                        if ('loyaltyGatedModal' in data) {
                            $('body').trigger('loyalty:enroll', {
                                type: 'genericLink',
                                loyalty: data.loyaltyGatedModal,
                                action: data.loyaltyGatedModal ? 'joined-yes' : 'joined-no',
                                member: 'new_member',
                                points_earned: 0
                            });
                        }
                        if (data.shouldRedirect || $('.js-login-in-page').length > 0 || $('.b-order-confirmation').length > 0 || 'loyaltyGatedModal' in data) {
                            location.href = data.redirectUrl;
                        } else {
                            setRegisteredCookie();
                            window.location.reload();
                        }
                    }
                },
                error: function (data) {
                    if (data && data.responseJSON && data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        button.prop('disabled', false);
                        button.removeAttr('style');
                        buttonContainer.spinner().stop();
                    }
                }
            });
        } else {
            if ($('#registration-form-password').val().length) {
                inputFeedback.show();
            }
            button.prop('disabled', false);
            button.removeAttr('style');
            buttonContainer.spinner().stop();
        }
        return false;
    });
}

/**
 * On Submitting complete account on modal
 */
function submitCompleteAccount() {
    $('body').on('click', '.js-complete-account-button', function (e) {
        e.preventDefault();
        const emailListCheckbox = $('input[name="dwfrm_profile_customer_addtoemaillist"]');
        if (emailListCheckbox.is(':checked')) {
            const form = $(this).closest('form');
            const url = form.attr('action');
            const formData = form.serialize();
            const button = $(this);

            // Start spinner and disable button
            button.parent('div').spinner().start();
            button.css('color', 'transparent').attr('disabled', true);

            $.ajax({
                url: url,
                method: 'POST',
                data: formData,
                complete: function () {
                    $('#completeAccountModal').modal('hide');
                    button.parent('div').spinner().stop();
                    button.removeAttr('style').attr('disabled', false);
                },
                success: function (data) {
                    if (!data.error && data.email) {
                        const isMobile = layout.isMobileView();
                        const optInCode = isMobile ? 'registerEmailOptInForMobile' : 'registerEmailOptInForDesktop';

                        $('body').trigger('global:emailSubscribeSuccess', {
                            email: data.email,
                            merkleCode: optInCode,
                            signupLocation: optInCode,
                            emailHashed: ''
                        });
                    }
                }
            });
        } else {
            $('#completeAccountModal').modal('hide');
        }
    });
}

/**
 * Events after open modal
 */
function registerCreateAccountEvents() {
    $('body').on('login:afterCreateAccountModal', function () {
        submitCreateAccount();
    });
}

/**
 * Events after open complete account modal
 */
function registerCompleteAccountEvents() {
    $('body').on('register:afterCompleteAccountModal', function () {
        submitCompleteAccount();
    });
}

/**
 * @param {Object} $this current element
 * request to render the create acccount modal
 */
function openCreateAccount($this) {
    $.spinner().start();
    $('body').find('.b-loader').css('z-index', '999');
    $.ajax({
        url: $this.data('href') || $this.attr('href'),
        data: { format: 'ajax' },
        error: function (err) {
            $.spinner().stop();
            console.log(err);
        },
        success: function (data) {
            $.spinner().stop();
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
                return;
            }
            $('body').find('.modal-backdrop.show').remove();
            $('.modal-body').empty();
            $('.modal-body').html(data);
            $('body').trigger('login:afterCreateAccountModal');
            util.branchCloseJourney();
            $('#newUserRegisterModal').modal('show');
            $('body').trigger('modalShown', { name: 'register' });
            $('#newUserRegisterModal').next('.modal-backdrop.show').css('z-index', '999');
        }
    });
}

/**
 * redirect to regidtration url
 * @param {Object} $this current element
 */
function redirectToRegistrationUrl($this) {
    $.spinner().start();
    $('.b-loader').css('z-index', '999');
    const { pageRef } = window.GLOBAL_VALUES || {};
    var params = {
        format: 'ajax',
        pageRef: pageRef
    };
    var url = $this.data('href') || $this.attr('href');
    if ($('.js-login-in-page').length > 0 || $('.b-order-confirmation').length > 0) {
        params.redirectAfterLogIn = true;
    }
    $.ajax({
        url: url,
        data: params,
        complete: function () {
            $.spinner().stop();
        },
        success: function (data) {
            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('Redirect URL is missing in the response.');
            }
        },
        error: function (xhr) {
            console.error('Error during the request:', xhr.status, xhr.statusText);
        }
    });
}

/**
 * Register event to open create account modal
 */
function openCreateAccountModal() {
    $('body').on('click', '.js-register', function (e) {
        e.preventDefault();
        $('#newUserRegisterModal').remove();
        if ($('#loginModal').length > 0) {
            $('#loginModal').remove();
        }
        if ($(this).closest('.js-login-page').length > 0) {
            var loginUrl = $(this).attr('data-href');
            window.location.href = loginUrl;
            return false;
        }
        if ($('.js-register-in-page').length > 0) {
            return false;
        }
        if ($(this).hasClass('secure-auth-init')) {
            redirectToRegistrationUrl($(this));
        } else {
            getModalHtmlElement();
            openCreateAccount($(this));
        }
        return true;
    });
}

/**
 * Register event to open account created modal
 */
function openAccountCreatedModal() {
    $(document).ready(function () {
        if (getCookie('showRegisteredModal') === 'true') {
            if ($('#userRegisteredModal').length !== 0) {
                $('#userRegisteredModal').remove();
            }
            var htmlString = '<!-- Modal -->'
                + '<div class="modal g-modal g-modal-userRegistered" id="userRegisteredModal" role="dialog">'
                + '<div class="modal-dialog g-modal-dialog ">'
                + '<!-- Modal content-->'
                + '<div class="modal-content g-modal-content g-modal-content-user-registered">'
                + '<div class="modal-body g-modal-body"></div>'
                + '</div>'
                + '</div>'
                + '</div>';
            $('body').append(htmlString);

            $.spinner().start();
            $('body').find('.b-loader').css('z-index', '999');

            $.ajax({
                url: $('#account-created-modal-url').val(),
                method: 'GET',
                error: function () {
                    $.spinner().stop();
                },
                success: function (data) {
                    $.spinner().stop();
                    deleteRegisteredCookie();
                    $('body').find('.modal-backdrop.show').remove();
                    $('body').addClass('m-no-scroll');
                    $('.modal-body').empty();
                    $('.modal-body').html(data);
                    $('#userRegisteredModal').modal('show');
                    $('body').trigger('modalShown', { name: 'registered' });
                    $('#userRegisteredModal').next('.modal-backdrop.show').css('z-index', '999');
                }
            });
        }
    });

    $('body').on('click', '.continue-shopping-container, .g-registered-modal-close_button', function (e) {
        e.preventDefault();
        $('#userRegisteredModal').modal('hide');
        $('body').removeClass('m-no-scroll');
    });
}

/**
 * Register event to open complete account modal
 */
function openCompleteAccountModal() {
    $(document).ready(function () {
        if (getCookie('showCompleteAccountModal') === 'true') {
            const modalId = '#completeAccountModal';

            // Remove existing modal if it exists
            $(modalId).remove();

            // Append modal HTML
            $('body').append(`
                <!-- Modal -->
                <div class="modal g-modal g-modal-complete-account" id="completeAccountModal" role="dialog">
                    <div class="modal-dialog g-modal-dialog">
                        <!-- Modal content -->
                        <div class="modal-content g-modal-content g-modal-content-complete-account">
                            <div class="modal-body g-modal-body"></div>
                        </div>
                    </div>
                </div>
            `);

            // Start spinner
            $.spinner().start();
            $('.b-loader').css('z-index', '999');

            // Fetch modal content via AJAX
            $.ajax({
                url: $('#account-created-modal-url').val(),
                method: 'GET',
                complete: function () {
                    $.spinner().stop();
                },
                success: function (data) {
                    deleteRegisteredCookie('showCompleteAccountModal');
                    $('body').addClass('m-no-scroll');

                    // Populate and display modal
                    $('.modal-body').html(data);
                    $('body').trigger('register:afterCompleteAccountModal');
                    $(modalId).modal('show');

                    // Trigger event and adjust z-index
                    $('body').trigger('modalShown', { name: 'completeAccount' });
                }
            });

            $(modalId).on('hide.bs.modal', function () {
                $('body').removeClass('m-no-scroll');
            });
        }
    });
}

/**
 * Register event consecutive space validator
 */
function consecutiveSpaceValidator() {
    var counter = 0;
    $('body').on('keydown', '#registration-form-email, #registration-form-password', function (e) {
        if (e.which === 32 || e.keyCode === 32) {
            counter += 1;
            if (counter > 1) {
                e.preventDefault();
            }
        } else {
            counter = 0;
        }
    });
}

/**
 * Open register popup if page redirect from header section
 */
function openRegisterOnPageLoad() {
    if ($('input[name="showRegisterModal"]').val() === 'true') {
        $('.b-header_account-link.js-register').trigger('click');
    }
}


module.exports = {
    registerCreateAccountEvents: registerCreateAccountEvents,
    registerCompleteAccountEvents: registerCompleteAccountEvents,
    openCreateAccountModal: openCreateAccountModal,
    openAccountCreatedModal: openAccountCreatedModal,
    consecutiveSpaceValidator: consecutiveSpaceValidator,
    openRegisterOnPageLoad: openRegisterOnPageLoad,
    openCompleteAccountModal: openCompleteAccountModal
};
